<template>
  <div class="payments-table">
    <v-card>
      <v-card-title
        v-if="userId || invoiceId"
        class="mb-0 pb-1"
      >
        Payment Details
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isPaymentsRefreshing"
                @click="refreshPayments"
              />
            </v-col>
            <v-col
              class="col-md-6 text-md-right"
            >
              <table-filter
                v-if="!invoiceId"
                :table-name="'payments'"
                :show-date-filter="true"
                :show-payment-method-filter="true"
                :show-payment-analytics-category-filter="true"
                :show-configuration-details="true"
                :filters="tableFilters"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
                @filterLabelChange="onFilterLabelChange"
              />
            </v-col>
            <v-col class="col-md-3 text-md-right">
              <export-button
                :title="exportTitle"
                :headers="exportHeaders"
                :data-endpoint="constructCompletePaymentsUrl"
                :data-path="'payments'"
                :orientation="'landscape'"
              />
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="filteredSearchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="filteredHeaders"
        :items="payments"
        :loading="isPaymentsLoading"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        :footer-props="footerProps"
        class="clickable-data-table"
        @click:row="onRowClick"
      >
        <template v-slot:item.customer.user.name="{ item }">
          {{ item.mpesa_payment ? item.mpesa_payment.first_name : item.customer.user.name }}
        </template>
        <template v-slot:item.customer.account_number="{ item }">
          {{ item.customer.account_number }}
        </template>
        <template v-slot:item.customer.user.phone_number="{ item }">
          {{ item.mpesa_payment ? item.mpesa_payment.msisdn : item.customer.user.phone_number }}
        </template>
        <template v-slot:item.amount="{ item }">
          Ksh {{ item.amount | formatCurrency }}
        </template>
        <template v-slot:item.customer.station.name="{ item }">
          {{ item.customer.station.name }}
        </template>
        <template v-slot:item.payment_method="{ item }">
          <v-chip
            class="ma-2"
            :color="getChipColor(item.payment_method)"
          >
            {{ item.payment_method }}
          </v-chip>
        </template>
        <template v-slot:item.payment_for="{ item }">
          {{ item.payment_for.map(payment => payment.description).join(', ') }}
        </template>
        <template v-slot:item.payment_date="{ item }">
          {{ item.payment_date | formatDate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                icon
                v-bind="attrs"
                :loading="viewingPaymentReceiptsLoading[item.id]"
                :disabled="viewingPaymentReceiptsLoading[item.id]"
                @click.stop="viewPaymentReceipt(item.id)"
                v-on="on"
              >
                <v-icon v-bind="attrs">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View receipt pdf</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                :loading="loadingPaymentReceipts[item.id]"
                :disabled="loadingPaymentReceipts[item.id]"
                @click.stop="downloadPaymentReceipt(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDownload }}
                </v-icon>
              </v-btn>
            </template>
            <span>Download receipt pdf</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="sendPaymentReceipt(item)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiSend }}
                </v-icon>
              </v-btn>
            </template>
            <span>Send receipt pdf</span>
          </v-tooltip>
          <v-tooltip
            v-if="can('payment-delete')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="showDeletePaymentDialog(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <add-payment-dialog
      :show-dialog="showAddPaymentDialog"
      :user-id="userId"
      @close="$emit('close-payment-dialog')"
      @paymentAdded="refreshPayments"
    />
    <send-payment-receipt-dialog
      :show-dialog="paymentReceiptToSend.showDialog"
      :email="paymentReceiptToSend.email"
      :payment-id="paymentReceiptToSend.paymentId"
      @close="paymentReceiptToSend.showDialog = false"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :title="'Delete payment?'"
      :agree-text="'Delete'"
      :message="'Are you sure you want to delete this payment? This action can not be reversed.'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deletePayment()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import {
  mdiEyeOutline, mdiDownload, mdiSend, mdiDelete,
} from '@mdi/js'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import ExportButton from '@/components/partials/ExportButton.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import AddPaymentDialog from '@/components/dialogs/AddPaymentDialog.vue'
import SendPaymentReceiptDialog from '@/components/dialogs/SendPaymentReceiptDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    RefreshButton,
    TableFilter,
    ExportButton,
    SearchInput,
    AddPaymentDialog,
    SendPaymentReceiptDialog,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  props: {
    paymentsUrl: {
      type: String,
      default: null,
    },
    showAddPaymentDialog: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: null,
    },
    invoiceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      payments: [],
      isPaymentsLoading: true,
      isPaymentsRefreshing: false,
      pagination: {
        totalItems: 0,
      },
      defaultDatePickerDate: '',
      options: {
        sortBy: ['payment_date'],
        sortDesc: [true],
      },
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Items per page:',
      },
      search: '',
      tableFilters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
        paymentMethod: {
          mpesa: true,
          creditCard: true,
          paypal: true,
          bankTransfer: true,
          cash: true,
        },
        customerStatus: {
          staticIp: false,
          pppoe: false,
          dynamicIp: false,
          hotspot: false,
        },
        paymentType: 1,
      },
      searchFilters: [
        { text: 'Name', value: 'customer.user.name' },
        { text: 'Account Number', value: 'customer.user.account_number' },
        { text: 'Phone Number', value: 'customer.phone_number' },
        { text: 'Reference', value: 'mpesaPayment.trans_id' },
        { text: 'Amount', value: 'amount' },
      ],
      searchColumn: 'mpesaPayment.trans_id',
      icons: {
        mdiEyeOutline,
        mdiDownload,
        mdiSend,
        mdiDelete,
      },
      loadingPaymentReceipts: {},
      viewingPaymentReceiptsLoading: {},
      paymentReceiptToSend: {
        showDialog: false,
        email: '',
        paymentId: '',
      },
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: '',
      exportTitle: 'Payments',
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    headers() {
      const headers = [
        { text: 'Name', value: 'customer.user.name' },
        { text: 'Account Number', value: 'customer.account_number' },
        { text: 'Phone Number', value: 'customer.user.phone_number' },
        { text: 'Reference', value: 'reference' },
        { text: 'Amount', value: 'amount' },
        { text: 'Payment Method', value: 'payment_method' },
        { text: 'Payment For', value: 'payment_for', sortable: false },
        { text: 'Date', value: 'payment_date' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]

      if (this.selectedStation === 'all') {
        headers.splice(headers.length - 1, 0, { text: 'Station', value: 'customer.station.name' })
      }

      return headers
    },
    filteredHeaders() {
      if (this.userId) {
        return this.headers.filter(header => header.text !== 'Name')
      }

      return this.headers
    },
    exportHeaders() {
      const exportHeaders = this.filteredHeaders.filter(header => header.value !== 'actions')

      // Check if 'paid_to' header is already present, if not, add it
      if (!exportHeaders.some(header => header.value === 'paid_to.name')) {
        exportHeaders.push({ text: 'Paid To', value: 'paid_to.name' })
      }

      return exportHeaders
    },
    filteredSearchFilters() {
      if (this.userId) {
        return this.searchFilters.filter(filter => filter.text !== 'Name')
      }

      return this.searchFilters
    },
    constructCompletePaymentsUrl() {
      const params = new URLSearchParams({
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDirection: this.options.sortDesc[0] ? 'desc' : 'asc',
        search: this.search,
        searchColumn: this.searchColumn,
        fromDate: this.tableFilters.dates.fromDate,
        toDate: this.tableFilters.dates.toDate,
        mpesa: this.tableFilters.paymentMethod.mpesa,
        creditCard: this.tableFilters.paymentMethod.creditCard,
        paypal: this.tableFilters.paymentMethod.paypal,
        bankTransfer: this.tableFilters.paymentMethod.bankTransfer,
        cash: this.tableFilters.paymentMethod.cash,
        paymentCategory: this.tableFilters.paymentType,
        staticIp: this.tableFilters.customerStatus.staticIp,
        pppoe: this.tableFilters.customerStatus.pppoe,
        dynamicIp: this.tableFilters.customerStatus.dynamicIp,
        hotspot: this.tableFilters.customerStatus.hotspot,

        // Include the invoiceId if it exists
        ...(this.invoiceId && { invoiceId: this.invoiceId }),
      })

      // Conditional inclusion of userId or stationId
      if (this.userId) {
        params.append('userId', this.userId)
      } else {
        params.append('stationId', this.selectedStation)
      }

      return `${this.paymentsUrl}?${params.toString()}`
    },

  },
  watch: {
    options: {
      handler() {
        this.fetchPayments()
      },
      deep: true,
    },
    search() {
      this.fetchPayments()
    },
    selectedStation() {
      this.fetchPayments()
    },
    searchColumn() {
      if (this.search) {
        this.fetchPayments()
      }
    },
  },
  mounted() {
    if (this.userId || this.invoiceId) {
      this.searchColumn = 'mpesaPayment.trans_id'
      this.defaultDatePickerDate = 'Last 1 year'
    } else {
      this.defaultDatePickerDate = 'Last 30 days'
    }
    this.fetchPayments()
  },
  methods: {
    fetchPayments: _.debounce(function () {
      this.isPaymentsLoading = true
      axios
        .get(this.constructCompletePaymentsUrl)
        .then(response => {
          this.payments = response.data.payments.data
          this.pagination.totalItems = response.data.payments.total
        })
        .catch(error => {
          console.error('Error fetching payments:', error)
        })
        .finally(() => {
          this.isPaymentsLoading = false
          this.isPaymentsRefreshing = false
        })
    }, 500),
    getChipColor(paymentMethod) {
      switch (paymentMethod) {
        case 'Cash':
          return '#FFC107' // Yellow color for Cash
        case 'Credit card':
          return '#2196F3' // Blue color for Credit card
        case 'Debt card':
          return '#4CAF50' // Green color for Debt card
        case 'Mpesa':
          return '#FF9800' // Orange color for Mpesa
        case 'Bank transfer':
          return '#9C27B0' // Purple color for Bank transfer
        default:
          return '#448AFF' // Default color for other payment methods
      }
    },
    refreshPayments() {
      this.isPaymentsRefreshing = true
      this.fetchPayments()
    },
    applyFilters(filters) {
      this.tableFilters = { ...this.tableFilters, ...filters }
      this.fetchPayments()
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    onRowClick(row) {
      this.$router.push({ name: 'payments.show', params: { id: row.id } })
    },
    onFilterLabelChange(label) {
      this.exportTitle = label
    },
    viewPaymentReceipt(id) {
      this.$set(this.viewingPaymentReceiptsLoading, id, true)
      axios.get(`/payments/${id}/download`, { responseType: 'blob' })
        .then(response => {
          const pdfBlob = response.data
          const pdfUrl = URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error viewing payment-receipt:', error)

          // handle errors
        }).finally(() => {
          this.$set(this.viewingPaymentReceiptsLoading, id, false)
        })
    },
    sendPaymentReceipt(payment) {
      this.paymentReceiptToSend.showDialog = true
      this.paymentReceiptToSend.email = payment.customer.user.email
      this.paymentReceiptToSend.paymentId = payment.id
    },
    downloadPaymentReceipt(id) {
      this.$set(this.loadingPaymentReceipts, id, true)
      const url = `/payments/${id}/download`

      axios({
        method: 'GET',
        url,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)

        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition']

        let filename = 'payment-receipt.pdf' // default filename

        if (contentDisposition) {
          const filenameRegex = /filename="?([^"]+)"?\b/
          const matches = contentDisposition.match(filenameRegex)
          if (matches && matches[1]) {
            filename = matches[1]
          }
        }

        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        console.error('There was an error downloading the payment-receipt:', error)
        this.$toast.error('There was an error downloading the payment-receipt')
      }).finally(() => {
        this.$set(this.loadingPaymentReceipts, id, false)
      })
    },
    showDeletePaymentDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deletePayment() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`payments/${this.toDelete}`)
        .then(() => {
          this.refreshPayments()
          this.$toast.success('Payment deleted successfully')
        }).catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
  },
}
</script>
